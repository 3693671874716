<template>
    <div class="org">
        <header class="header">
            <span
                v-for="(item,index) in componentLs"
                :key="index"
                :class="['tag',$route.path === item.path?'active':'']"
                @click="handleChange(item)"
            >{{ item.title }}</span>
        </header>
        <div class="main-box">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: 'org',
    data() {
        return {
            loading: false,
            componentLs: [
                {
                    title: '角色',
                    path: '/admin/org/role',
                },
                {
                    title: '部门',
                    path: '/admin/org/org',
                },
                {
                    title: '用户',
                    path: '/admin/org/user',
                },
                {
                    title: '外部用户注册',
                    path: '/admin/org/enroll',
                },
            ],
        };
    },

    methods: {
        // 切换选项
        handleChange(item) {
            this.$router.push(item.path);
        },
    },
};
</script>

<style lang="stylus" scoped>

.org
    width: 100%;
    padding: 0.2rem;
    background #F0F0F0
    .header
        display flex
        justify-content flex-start
        align-items flex-end
        padding  0 0.2rem 0.1rem;
        height auto;
        .tag
            margin-right 0.6rem;
            box-sizing border-box
            padding 0.06rem 0.1rem
            font-size 18px;
            color #666666;
            border-bottom 0;
            cursor pointer
            &.active
                color #1577D2;
                border-bottom 0.02rem solid #1577D2;
    .main-box
        box-sizing border-box
        height calc(100% - 0.5rem);
</style>
